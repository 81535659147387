import React, { useEffect } from "react";
import Wrapper from "../../components/wrapper";
import StepPagination from "../../components/stepPagination";
import { IFirstProps } from "../first";
import { useTranslation } from "react-i18next";
import { Button, Col, Row, Spinner, Table } from "react-bootstrap";
import { clearLocalStorageItems } from "../../helpers";
import CalendarImg from "./img/calendar.svg";
import GeoImg from "./img/geo.svg";
import StarImg from "./img/star.svg";
import {
  useGetEventProxyQuery,
  usePublishEventMutation,
  useTimezonesProxyQuery,
} from "../../qraphql/event.hooks";
import { useNavigate } from "react-router-dom";
import useFormatedDate from "../../hooks/useFormatedDate";
import useGetUrlLocale from "../../hooks/useGetUrlLocale";
import { TicketTypeEnum } from "../../types";

export default function Fourth({
  entity,
  handleUpdateEntity,
  step,
}: IFirstProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const urlLocale = useGetUrlLocale();
  const { formatedDate } = useFormatedDate();

  const { data, loading } = useGetEventProxyQuery({
    variables: { eventId: entity.id },
    fetchPolicy: "network-only",
  });
  const { data: timezoneData, loading: timezoneLoading } =
    useTimezonesProxyQuery();
  const [publishEvent, { data: publishData, loading: publishLoading }] =
    usePublishEventMutation();

  const handlePublish = async () => {
    await publishEvent({ variables: { eventId: entity.id } });
  };

  const timeZoneTime = timezoneData.timezonesProxy.find(
    (el) => el.id.toString() === entity.timezoneId
  )?.gmt;

  useEffect(() => {
    if (publishData?.publishEvent) {
      clearLocalStorageItems();
      window.location.href = entity.isOnline
        ? `${process.env.REACT_APP_TICKET_URL}/for-organizers/events/${entity.id}/add-banner`
        : `${process.env.REACT_APP_TICKET_URL}/for-organizers/events/${entity.id}/scanner-promotion`;
    }
  }, [publishData]);

  if (loading || timezoneLoading) {
    return (
      <Wrapper>
        <h1 className="fw-bold fs-1 mb-4 mb-md-5">
          {t("firstStep.createEvent")}
        </h1>
        <StepPagination step={step} />
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ height: 200 }}
        >
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <h1 className="fw-bold fs-1 mb-4 mb-md-5">
        {t("firstStep.createEvent")}
      </h1>
      <StepPagination step={step} />
      <div className="fw-bold fs-4 mb-3">{t("fourthStep.Event Preview")}</div>
      <div className="p-3 px-4 border rounded-3 border-sec-background">
        <Row className="my-2 gy-3">
          <Col lg={4}>
            <div className="h-100 d-flex justify-content-center align-items-center">
              <img
                src={data.eventProxy?.posterImage?.link}
                alt="event"
                className="img-fluid rounded-3"
              />
            </div>
          </Col>
          <Col lg={8} className="d-flex flex-column justify-content-between">
            <div>
              <div className="fw-bold fs-4 mb-3">{data.eventProxy?.title}</div>
              <div className="d-flex align-items-start mt-2">
                <img src={CalendarImg} alt="calendar" className="me-2" />
                {`${formatedDate(data.eventProxy.isRecurring ? data.eventProxy?.recurrence.startDate : data.eventProxy?.startDate)} - ${formatedDate(
                  data.eventProxy.isRecurring
                    ? data.eventProxy?.recurrence.endDate
                    : data.eventProxy?.endDate
                )}${timeZoneTime ? ` (GMT ${timeZoneTime})` : ""}`}
              </div>
              <div className="d-flex align-items-start mt-2">
                <img src={GeoImg} alt="geo" className="me-2" />
                {data.eventProxy.isOnline ? (
                  <span>
                    {t("fourthStep.Online")}:{" "}
                    <a
                      href={data.eventProxy?.platform?.invitationLink}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {data.eventProxy?.platform?.invitationLink}
                    </a>
                  </span>
                ) : (
                  <span>{`${data.eventProxy?.place?.city?.city}, ${data.eventProxy?.place?.name}, ${data.eventProxy?.place?.city?.country?.country}`}</span>
                )}
              </div>
              {data.eventProxy?.performers.length > 0 && (
                <div className="d-flex align-items-start mt-2">
                  <img src={StarImg} alt="star" className="me-2" />
                  <div>
                    {data.eventProxy?.performers
                      .map((item) => item.name)
                      .join(", ")}
                  </div>
                </div>
              )}
              <div className="text-info text-wrap overflow-hidden mt-3">
                {data.eventProxy?.description}
              </div>
            </div>
            <div className="d-flex gap-2 flex-wrap mt-3">
              {data.eventProxy?.categories.map((item) => (
                <div
                  key={item.id}
                  className="py-1 px-2 bg-success-bg rounded text-success fs-7 d-flex align-items-center justify-content-center"
                >
                  {item.title}
                </div>
              ))}
            </div>
          </Col>
        </Row>
      </div>
      <div className="p-3 px-4 border rounded-3 border-sec-background mt-4">
        <div className="fw-bold fs-4 mb-3">{t("fourthStep.Tickets")}</div>
        <Table borderless responsive="lg">
          <thead className="bg-light-gray">
            <tr>
              <th className="text-info">{t("firstStep.type")}</th>
              <th className="text-info">{t("secondStep.description")}</th>
              <th className="text-info">{t("secondStep.price")}</th>
              <th className="text-info">{t("fourthStep.Available")}</th>
            </tr>
          </thead>
          <tbody>
            {data.eventProxy.tickets.map((item) => (
              <tr key={item.id} style={{ lineHeight: 3 }}>
                <td className="fw-bold">{item.type.name}</td>
                <td>{item.description || "-"}</td>
                <td>
                  <span className="p-2 bg-background-gray rounded text-nowrap">
                    {`${item.price || "0"} ${data.eventProxy.tickets.find((el) => el?.currency)?.currency || "USD"}`}
                  </span>
                </td>
                <td>
                  <span className="p-2 bg-background-gray rounded">
                    {item.quantity}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      {data.eventProxy?.ticketReturn?.isPossible && (
        <div className="p-3 px-4 border rounded-3 border-sec-background mt-4">
          <div className="fw-bold fs-4 mb-3">
            {t("fourthStep.Returns Information")}
          </div>
          <div className="text-info">
            {data.eventProxy?.ticketReturn?.description}
          </div>
        </div>
      )}
      <Row className="justify-content-between my-5">
        <Col xs={4} lg={3}>
          <Button
            variant="outline-success"
            className="w-100 py-3 fw-normal"
            onClick={() => {
              const isSkipPaymentStep =
                entity.isOnline &&
                entity.tickets.every(
                  (ticket) =>
                    (ticket.ticketType === TicketTypeEnum.Free &&
                      ticket.active === true) ||
                    (ticket.ticketType !== TicketTypeEnum.Free &&
                      ticket.active === false)
                );
              if (isSkipPaymentStep) {
                handleUpdateEntity(entity, 1);
                navigate(`${urlLocale}/tickets-info/${entity.id}/edit`);
                return;
              }
              handleUpdateEntity(entity, 2);
              navigate(`${urlLocale}/payment-info/${entity.id}/edit`);
            }}
          >
            {t("secondStep.back")}
          </Button>
        </Col>
        <Col xs={8} lg={4}>
          <Button
            variant="success"
            className="fw-bold w-100 py-3"
            onClick={handlePublish}
            disabled={publishLoading || publishData?.publishEvent}
          >
            {t("fourthStep.Publish & Promote")}
            {(publishLoading || publishData?.publishEvent) && (
              <Spinner
                animation="border"
                role="status"
                size="sm"
                className="ms-2"
              />
            )}
          </Button>
        </Col>
      </Row>
      {publishData?.publishEvent && !publishData?.publishEvent && (
        <div className="bg-danger bg-opacity-10 text-danger p-3 rounded">
          Event creation is not finished
        </div>
      )}
    </Wrapper>
  );
}
